var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vxe-toolbar", {
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "tools-box" },
                  [
                    !_vm.disabled
                      ? _c(
                          "el-upload",
                          {
                            attrs: {
                              action: "/upload/uploadController/uploadOssList",
                              headers: {
                                loginUserToken: _vm.Storage.l.get("TokenKey"),
                              },
                              multiple: "",
                              "show-file-list": false,
                              "on-exceed": _vm.handleExceed,
                              "before-upload": _vm.beforeUpload,
                              "on-preview": _vm.handlePreview,
                              "on-remove": _vm.handleRemove,
                              "before-remove": _vm.beforeRemove,
                              "on-success": _vm.handleSuccess,
                              limit: _vm.limit,
                              accept: _vm.accept || "",
                              "file-list": _vm.fileList,
                            },
                          },
                          [_c("el-button", [_vm._v("上传附件")])],
                          1
                        )
                      : _vm._e(),
                    _vm.tips.length > 0
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "tips-item",
                            attrs: { placement: "right" },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              _vm._l(_vm.tips, function (item, k) {
                                return _c("span", { key: k }, [
                                  _vm._v(_vm._s(item)),
                                  _vm.tips.length > 1 ? _c("br") : _vm._e(),
                                ])
                              }),
                              0
                            ),
                            _c("i", { staticClass: "el-icon-question" }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "vxe-table",
        { ref: "uploadTable", attrs: { data: _vm.value } },
        [
          _c("vxe-table-column", {
            attrs: { field: "objectName", title: "附件名称" },
          }),
          _c("vxe-table-column", {
            attrs: {
              title: "操作",
              width: "200",
              align: "center",
              "show-overflow": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "down-link",
                        attrs: {
                          href:
                            "/upload/downloadController/download?objectName=" +
                            row.fileAddress,
                          download: row.objectName,
                        },
                      },
                      [_vm._v("下载")]
                    ),
                    !_vm.disabled
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleRemove(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }