import Form, { formCreate } from '../../components/form';
import UpdateTable from '../upload_modal/upload_table.vue';
import Storage from '../../utils/storage';
import request from '../../utils/request';

formCreate.component('UpdateTable', UpdateTable);

export default {
  extends: Form,
  props: {
    defaultValue: Object,
  },
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [
        {
          type: 'input',
          field: 'fullName',
          title: '申请人',
          props: {
            disabled: true,
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'orgName',
          title: '部门',
          props: {
            disabled: true,
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'select',
          field: 'workFlowKey',
          title: '流程名称',
          required: true,
          on: {
            change: (e) => this.workFlowKeyChange(e),
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'hidden',
          field: 'workFlowName',
          title: '流程名称',
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'title',
          title: '主题',
          required: true,
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'approveRemarks',
          title: '详情',
          props: {
            type: 'textarea',
          },
          col: {
            md: { span: 24 },
          },
        },
        {
          type: 'UpdateTable',
          field: 'activityFileList',
          title: '附件',
          on: {
            onGetFileList: (e) => this.uploadChange(e),
          },
          col: {
            md: { span: 24 },
          },
          props: {
            tips: this.formConfig.tips || [],
          },
        },
      ],
      approveData: [],
    };
  },
  mounted() {
    this.getApprove();

    if (Object.keys(this.defaultValue).length > 0) {
      this.setValue(this.defaultValue);
    } else {
      this.getUserInfo();
    }
  },
  methods: {
    /**
     * 获取用户信息
     */
    getUserInfo() {
      const userInfo = Storage.l.get('userInfo', true);
      this.setValue({
        fullName: userInfo.fullName,
        orgName: userInfo.orgName,
      });
    },
    /**
     * 流程名称change事件
     * @params e 当前选中值
     */
    workFlowKeyChange(e) {
      const workFlowKeyOptions = this.getRule('workFlowKey').options;
      const selected = workFlowKeyOptions.find((item) => item.value === e);
      this.setValue({
        workFlowName: selected.label,
      });
    },
    /**
     * 文件上传change事件
     * @params e 当前组件的值
     */
    uploadChange(e) {
      this.setValue({
        activityFileList: e,
      });
    },
    /**
     * 获取流程
     */
    getApprove() {
      console.log(this.formConfig);
      if (this.formConfig.data === 'activity') {
        const budgetSubjectsCodeList = this.formConfig.row.budgetVos.map((v) => v.budgetSubjectsCode);
        const budgetSubjectsCode = budgetSubjectsCodeList.join(',');
        const budgetSubjectsNameList = this.formConfig.row.budgetVos.map((v) => v.budgetSubjectsName);
        const budgetSubjectsName = budgetSubjectsNameList.join(',');
        const productTypeList = this.formConfig.row.detailVos.map((v) => v.productType);
        const productType = productTypeList.join(',');
        const productTypeNameList = this.formConfig.row.detailVos.map((v) => v.productTypeName);
        const productTypeName = productTypeNameList.join(',');
        console.log(budgetSubjectsCode, budgetSubjectsName, productType, productTypeName);
        const params = {
          budgetSubjectsCode,
          budgetSubjectsName,
          productType,
          productTypeName,
        };
        request.post('/activiti/taActBaseController/getMarketActSubmitActiviti', params).then((res) => {
          if (res.success) {
            const list = res.result.details || [];
            this.approveData = list.map((item) => ({
              label: item.processName,
              value: item.processKey,
            }));
            const workFlowKey = this.getRule('workFlowKey');
            workFlowKey.options = this.approveData;

            if (list.length === 1) {
              console.log(list);
              this.setValue({
                workFlowKey: list[0].processKey,
                workFlowName: list[0].processName,
              });
            }
          }
        });
      } else {
        request.post('/activiti/taActBaseController/getSubmitActiviti').then((res) => {
          if (res.success) {
            const list = res.result.details || [];
            this.approveData = list.map((item) => ({
              label: item.processName,
              value: item.processKey,
            }));
            const workFlowKey = this.getRule('workFlowKey');
            workFlowKey.options = this.approveData;

            if (list.length === 1) {
              console.log(list);
              this.setValue({
                workFlowKey: list[0].processKey,
                workFlowName: list[0].processName,
              });
            }
          }
        });
      }
    },
    /**
     * 提交
     */
    submit() {
      const formData = this.getFormData();
      console.log(formData);

      if (formData) {
        const { submitUrl, row, resetData } = this.formConfig;
        let params = {
          ...row,
          ...formData,
        };

        if (resetData && typeof resetData === 'function') {
          params = resetData(row, formData);
        }

        request.post(submitUrl, params).then((res) => {
          if (res.success) {
            this.$message.success('提交成功！');
            this.$emit('submit');
          }
        });
      }
    },
  },
};
