<template>
  <div>
    <vxe-toolbar>
      <template #buttons>
        <div class="tools-box">
          <el-upload
          v-if="!disabled"
          action="/upload/uploadController/uploadOssList"
          :headers="{
            loginUserToken: Storage.l.get('TokenKey')
          }"
          multiple
          :show-file-list="false"
          :on-exceed="handleExceed"
          :before-upload="beforeUpload"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-success="handleSuccess"
          :limit="limit"
          :accept="accept || ''"
          :file-list="fileList"
        >
          <el-button>上传附件</el-button>
        </el-upload>
        <el-tooltip class="tips-item" placement="right" v-if="tips.length > 0">
          <div slot="content">
            <span v-for="(item,k) in tips" :key="k">{{item}}<br v-if="tips.length > 1"/></span>
          </div>
          <i class="el-icon-question"></i>
        </el-tooltip>
        </div>
      </template>
    </vxe-toolbar>
    <vxe-table ref="uploadTable" :data="value">
      <vxe-table-column field="objectName" title="附件名称"></vxe-table-column>
      <vxe-table-column title="操作" width="200" align="center" show-overflow>
        <template #default="{ row }">
          <a :href="`/upload/downloadController/download?objectName=${row.fileAddress}`" :download="row.objectName" class="down-link">下载</a>
          <!-- <el-button type="text" size="mini" @click="downLoad(row)">下载</el-button>&nbsp;&nbsp; -->
          <el-button type="text" size="mini" v-if="!disabled" @click="handleRemove(row)"
            >删除</el-button
          >
        </template>
      </vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
import Storage from '../../utils/storage';

export default {
  name: 'UploadModal',
  props: {
    value: Array,
    disabled: Boolean,
    limit: Number,
    accept: String,
    tips: Array,
  },
  watch: {},
  data() {
    return {
      Storage,
      modalConfig: {
        title: '选择',
      },
      fileList: [],
      isUpload: false,
    };
  },
  created() {},
  methods: {
    downLoad(row) {
      const file = new XMLHttpRequest();
      file.open('GET', row.url, true);
      file.responseType = 'blob';
      file.onload = () => {
        const content = file.response;
        const link = document.createElement('a');
        link.download = row.fileName;
        link.style.display = 'none';
        const blob = new Blob([content]);
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      };
      file.send();
    },
    beforeUpload(files) {
      const { name } = files;
      let fileType = name.split('.')[name.split('.').length - 1];
      fileType = fileType.toLowerCase();
      if (this.accept && this.accept.indexOf(fileType) === -1) {
        this.isUpload = true;
        this.$message.warning(`当前限制选择 ${this.accept} 类型件，本次选择了错误文件`);
        return false;
      }
      return true;
    },
    handleRemove(file) {
      const list = this.value.filter((v) => v.url !== file.url);
      this.$emit('onGetFileList', list);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleSuccess(res) {
      this.isUpload = false;

      if (res.code === 200 && res.success) {
        const data = res.result.map((item) => ({
          fileName: item.fileName,
          objectName: item.fileName,
          fileAddress: item.objectName,
        }));
        const list = JSON.parse(JSON.stringify(this.value || []));
        list.push(...data);

        this.$emit('onGetFileList', list);
      }
    },
    handleExceed(files, fileList) {
      console.log(files, fileList);
    },
    beforeRemove(file) {
      if (!this.isUpload) {
        return this.$confirm(`确定移除 ${file.name}？`);
      }
      this.isUpload = false;

      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.tools-box {
  display: flex;
  align-items: center;

  .tips-item {
    margin-left: 15px;
    font-size: 16px;
  }

}

.down-link {
  margin-right: 15px;
}
</style>
